import React from 'react';
import Layout from '../components/common/layout/layout';
import {
    graphql
} from 'gatsby'

import styled from "styled-components"

import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import {
    Container
} from "../components/global"
import Footer from "../components/sections/footer"

import { useFirebase } from "gatsby-plugin-firebase"

function BlogPost(props) {
    const post = props.data.page;
    const {
        title
    } = post.frontmatter;

    useFirebase(firebase => { firebase.analytics().logEvent("page_view" + title) }, [])

    return (
      <Layout>
        <SEO title="apocha" />
        <Navigation />
        <BlogPostContainer>
          <SidebarNav id="docsSidebarNav">
            <SidebarNavSectionHeader>Allgemein</SidebarNavSectionHeader>
            <SidebarNavList>
            {
              props.data.links.edges.map(({ node }, i) => 
              { 
                if(node.fields.slug.indexOf("allgemein") !== -1) {
                  return (<SidebarNavListItem key="{i}"><a href={node.fields.slug}>{node.frontmatter.title}</a></SidebarNavListItem>);
                } else {
                  return ("");
                }
              }
            )}
            </SidebarNavList>
            <SidebarNavSectionHeader>Konten</SidebarNavSectionHeader>
            <SidebarNavList>
            {
              props.data.links.edges.map(({ node }, i) => 
              { 
                if(node.fields.slug.indexOf("konten") !== -1) {
                  return (<SidebarNavListItem key="{i}"><a href={node.fields.slug}>{node.frontmatter.title}</a></SidebarNavListItem>);
                } else {
                  return ("");
                }
              }
            )}
            </SidebarNavList>
          </SidebarNav>
          <PageContent>
              <h4>{title}</h4>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </PageContent>
        </BlogPostContainer>
        <Footer />
      </Layout>
    )
}

export default BlogPost;

export const linkQuery = graphql `
  query DocsPageQuery($slug: String!) {
     page: markdownRemark(fields: { slug: { eq: $slug } }) {
       html
       frontmatter {
        title
      }
    }
    links: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(docs)/"  }},
      sort: { order: ASC, fields: [frontmatter___index] }) {
      edges {
        node {
          fields{
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

const SidebarNav = styled.div
`
  padding: 40px 30px 0px 0px;
`
const SidebarNavSectionHeader = styled.div
`
`
const SidebarNavList = styled.ul
`
  list-style-type: none;
  padding-inline-start: 10px;
`
const SidebarNavListItem = styled.li
`

`

const PageContent = styled.div
`
  
`

const BlogPostContainer = styled(Container)
`
  display: flex;
  flex-direction: row;
  padding: 80px 18px 40px;
`
